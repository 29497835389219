@import '../../../properties.css';

.top {
  min-height: 50vh;
}
.top__contents {
  padding: 0;
}

.top__map {
  background-color: var(--color-gray);
  width: 100%;
  height: 100%;
  margin-bottom: 48px;
}

/* chakura uiのtabの左右のpaddingを打ち消すため */
.top__tabs .chakra-tabs__tab-panels > div {
  padding-right: 0;
  padding-left: 0;
}

.top__contents-list-item {
  margin-bottom: 64px;
}

.top__tags {
  background-color: #fff;
  border-radius: 48px;
  padding: 8px 24px;
  margin-bottom: 24px;
}
