@import '../ImageCarousel/style.css';

.img-carousel-modal {
  --carousel-size-xl: 1200px;
  --carousel-size-lg: 960px;
  --carousel-size-sm: 100%;
  margin: 0 auto;
}

.img-carousel-modal .modal-xl {
  max-width: var(--carousel-size-xl);
}

.img-carousel-modal .modal-lg {
  max-width: var(--carousel-size-lg);
}

.img-carousel-modal .modal-sm {
  max-width: var(--carousel-size-sm);
}

.img-carousel-modal .modal-content {
  background-color: transparent;
  border: none;
}

.img-carousel-modal .modal-header {
  position: relative;
  border: none;
}

.img-carousel-modal .modal-sm .modal-body {
  padding: 0;
}

.img-carousel-modal .img-carousel-modal-icon {
  background-color: transparent;
  border: none;
}
.img-carousel-modal-icon.img-carousel-modal-icon__close--sm {
  color: var(--color-gray-dk);
  background: var(--color-gray-lt);
}

.img-carousel-modal-icon__close {
  position: fixed;
  top: 25px;
  right: 20px;
  z-index: 10;

  padding: 0px;
  border-radius: 50%;
}

/* .img-carousel-modal-icon__close--lg,
.img-carousel-modal-icon__close--xl {
  position: absolute;
  top: -25px;
  right: 40px;
} */

.img-carousel-modal-icon__close {
  color: #fff;
  opacity: 0.5;
}

.img-carousel-modal-icon:hover {
  opacity: 1;
}

.img-carousel-modal-icon__a {
  color: #fff;
  font-size: 2rem;
  opacity: 0.5;
}

.img-carousel-modal-icon__a--sm {
  top: -10px;
  left: 85px;
}

.img-carousel-modal-icon__a--lg,
.img-carousel-modal-icon__a--xl {
  top: -15px;
  left: 80px;
}

.img-carousel-modal-icon__b {
  color: #fff;
  font-size: 2rem;
  opacity: 0.5;
}

.img-carousel-modal-icon__b--sm {
  top: -10px;
  left: 150px;
}

.img-carousel-modal-icon__b--lg,
.img-carousel-modal-icon__b--xl {
  top: -15px;
  left: 150px;
}
