@import '../../../properties.css';

.icon {
  display: flex;
  align-items: center;
}

.icon__icon {
  display: flex;
  align-items: center;
  padding-right: 0.25rem;
}
