@import '../../../properties.css';

.menu-modal {
  background: rgba(0, 0, 0, 0.5);
}

.menu-modal .menu-modal__body {
  padding: 0;
}

.menu-modal .menu-modal__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu-modal .menu-modal__item {
  padding: 0.6rem;
  text-align: center;
}

.menu-modal .menu-modal__item:not(:last-child) {
  border-bottom: 1px solid var(--color-gray-lt);
}

.menu-modal .menu-modal__item a {
  display: block;
}
