.user-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
}

.user-menu__user {
  overflow: hidden;
  width: 100%;
}

.user-menu__name {
  float: left;
  width: calc(100% - 4.5rem);
  margin: 0;
  word-wrap: break-word;
  word-break: break-all;
}

.user-menu__follow {
  float: right;
  width: 4.5rem;
  color: var(--color-main);
  text-align: right;
  text-decoration: none;
}

.user-menu__follow:hover {
  text-decoration: none;
  cursor: pointer;
}

.user-menu__menu {
  width: 100%;
  margin-top: 0.2rem;
  font-size: 1.1rem;
}

.user-menu__menu-bar {
  float: right;
}

.user-menu__menu-bar:hover {
  color: var(--color-link);
  cursor: pointer;
}
