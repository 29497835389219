@import '../../../properties.css';

.post-card {
  position: relative;
}

.post-card:hover .post-card__body {
  visibility: visible;
  background: #333;
  opacity: 0.8;
  overflow-y: scroll;
  scrollbar-width: none;
}

.post-card:hover .post-card__body::-webkit-scrollbar {
  display: none;
}

.post-card__img {
  height: 200px;
  object-fit: cover;
}

.post-card__body {
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.post-card__tags {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: var(--font-size-s);
  letter-spacing: 0.15rem;
}

.post-card__title {
  margin: 0 0 0.2rem;
}

.post-card__item {
  margin: 0;
}

.post-card__user-name {
  margin: 0.3rem 0 0;
  font-size: var(--font-size-xs);
}

.post-card__icons {
  position: absolute;
  display: flex;
  bottom: 1rem;
  left: 1rem;
  margin: 0;
  padding: 0.5rem 0 0;
  color: #fff;
  font-size: var(--font-size-m);
  list-style: none;
}

.post-card__icon {
  color: #fcfbc5;
}

.post-card__icon:not(:last-child) {
  padding-right: 0.75rem;
}
