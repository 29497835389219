@import '../../../properties.css';

.post-comment {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.post-comment__image-grid-col.post-comment__image-grid-col {
  padding: 12px;
}

.post-comment__image-grid-row.post-comment__image-grid-row {
  margin: 0 auto;
}

.post-comment .post-card__img {
  height: 200px;
}

@media (max-width: 1067px) {
  .post-comment {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }
  .post-comment .post-card__img {
    height: 256px;
  }
}

@media (max-width: 768px) {
  .post-comment .post-card__img {
    height: 200px;
  }
}

@media (max-width: 568px) {
  .post-comment {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
}

@media (max-width: 428px) {
  .post-comment {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }

  .post-comment .post-card__img {
    height: 144px;
  }
}
