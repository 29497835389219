@import '../../../properties.css';

.start {
  height: 100vh;
  background: var(--color-gray-p);
  padding: 0;
}

.start__contents {
  margin: auto;
  max-width: 480px;
  /* padding-right:20px;
  padding-left:20px;
  padding-top: 64px; */
}
