@import '../../../properties.css';

.error-page {
  margin-top: 64px;
  padding: 24px;
}

.error-page__contents {
  margin: auto;
  padding-top: 3rem;
  /* text-align: center; */
}
.error-page__header {
  font-size: 1.5rem;
  line-height: 2;
  font-weight: 700;
  margin-bottom: 2rem;
}

.error-page__message {
  font-size: 1.1rem;
  margin-bottom: 2rem;
}
