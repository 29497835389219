@import '../../../properties.css';

.post-list__image-grid {
  padding: 0;
}

.post-list__image-grid-col {
  padding: 1rem;
}

@media (max-width: 575px) {
  .post-list__image-grid-row {
    max-width: 300px;
    margin: 0 auto;
  }
}
