@import '../../../properties.css';

.tag__post-list {
  margin-top: 1rem;
}

@media (max-width: 575px) {
  .tag__tags {
    width: 300px;
    margin: 0 auto;
    padding: 0 1rem;
  }

  .tag__post-list {
    margin-top: 0.75rem;
  }
}
