@import '../../../properties.css';

.comment-balloon {
  position: relative;
  display: inline-block;
  padding: 0.5rem;
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;
}

.comment-balloon::before {
  content: '';
  position: absolute;
  top: -9px;
  left: calc(50% - 7.5px);
  width: 15px;
  height: 15px;
  border-top: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
}

.comment-balloon--gray {
  background: var(--color-gray-p);
  border-color: var(--color-gray);
}

.comment-balloon--gray::before {
  background: var(--color-gray-p);
  border-color: var(--color-gray);
}

.comment-balloon--gray-lt {
  background: var(--color-gray-p);
  border-color: var(--color-gray-lt);
}

.comment-balloon--gray-lt::before {
  background: var(--color-gray-p);
  border-color: var(--color-gray-lt);
}

.comment-balloon--main,
.comment-balloon--main::before {
  background: #b8ecf5;
  border-color: var(--color-main);
}

.comment-balloon--sub,
.comment-balloon--sub::before {
  background: #b9f8f3;
  border-color: var(--color-sub);
}

.comment-balloon__text,
.comment-balloon__post-info {
  margin: 0;
}
