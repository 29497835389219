@import '../../../properties.css';

.search__map {
  background-color: var(--color-gray);
}

.search__contents {
  padding: 0;
}

.search__contents-list {
  margin-top: 2.5rem;
}

.search__contents-list-item {
  margin-top: 1.5rem;
}

.search__tags {
  background-color: #e3e3e3;
  border-radius: 2.5rem;
  padding: 0.5rem 1.5rem;
}
