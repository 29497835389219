@import '../../../properties.css';

.setting-page {
  margin-top: 64px;
  padding: 24px;
}

.setting-page__title h1 {
  font-size: 1.5rem;
  font-weight: 700;
}

.setting-page__contents {
  min-height: 24vh;
  margin-bottom: 10rem;
  padding: 0;
}

.setting-page__display-switching {
  font-size: var(--font-size-s);
}

.setting-page .setting-page__display-switching .nav-link.active {
  background: #f6f6f6;
  border-color: #dee2e6;
}
