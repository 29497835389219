@import '../../../properties.css';

.comment-card {
  height: 100%;
  font-size: var(--font-size-s);
  letter-spacing: 0.05rem;
  line-height: 1rem;
}

.comment-card .comment-card__header {
  background: var(--color-white);
}

.comment-card .comment-card__map-link {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0.3rem 0.5rem;
  font-size: 0.7rem;
  background: var(--color-link);
  border-radius: 1rem;
  margin-right: 8px;
}

.comment-card__btns {
  display: flex;
  justify-content: flex-start;
}

.comment-card__btns > button {
  margin: 0 2px;
}

.comment-card .comment-card__map-link:hover {
  text-decoration: none;
  opacity: 0.7;
}

.comment-card .comment-card__map-icon {
  color: #fff;
}

.comment-card .comment-card__map-label {
  color: #fff;
  padding-left: 0.25rem;
}

.comment-card .comment-card__content {
  margin-top: 20px;
}

.comment-card .comment-card__comment-list {
  margin: 1.5rem 0 0;
  padding: 0;
  list-style: none;
}

.comment-card .comment-card__comment-item:not(:first-child) {
  margin-top: 1rem;
}

.comment-card .comment-card__footer {
  overflow: hidden;
  background: var(--color-white);
}

.comment-card .comment-card__form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.comment-card .comment-card__input {
  width: 100%;
  padding: 0;
  font-size: var(--font-size-m);
  border: none;
}

.comment-card .comment-card__input:focus {
  box-shadow: none;
}

.comment-card .comment-card__input::placeholder {
  color: var(--color-gray);
}

.comment-card .comment-card__post {
  min-width: 6rem;
  margin: 0;
  color: var(--color-main);
  text-align: right;
  background: none;
  border: none;
  cursor: pointer;
  font-size: var(--font-size-m);
}

.comment-card .comment-card__post:hover {
  color: var(--color-link-hover);
}
