@import '../../../properties.css';

.post-form.post-form {
  max-width: 960px;
  margin: 0 auto;
}

.post-form .post-form__group {
  margin-bottom: 2rem;
}

.post-form .leaflet-container {
  width: 100%;
  height: 500px;
}

@media (max-width: 575px) {
  .post-form .leaflet-container {
    height: 400px;
  }
}

.post-form .post-form__group.post-form__group--disable .react-tag-input {
  background-color: #e9ecef;
  opacity: 1;
}

.post-form .post-form__group.post-form__group--disable .react-tag-input::after {
  content: 'スナップ場所の地域情報が入力されます';
  color: var(--color-gray);
  margin: 0 0.1875em;
}

/* チェックボックスと文章を中央に並べる */
.post-form .post-form__group .form-check {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-s);
}

.post-form .post-form__label {
  display: flex;
  align-items: center;
  font-size: var(--font-size-s);
}

.post-form .post-form__label--large {
  margin-bottom: 1.5rem;
}

.post-form .post-form__label.post-form__label--center {
  justify-content: center;
  margin-bottom: 1rem;
}

.post-form .post-form__label-text {
  display: inline-block;
  margin-right: 0.75rem;
}

.post-form .post-form__photograph {
  margin-top: 3.5rem;
}

.post-form .post-form__map {
  position: relative;
}

.post-form .post-form__map .leaflet-container {
  /* height:300px; */
}

.post-form .post-form__search {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 250px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  z-index: 400;
}

.post-form .was-validated .post-form__search-box.form-control:valid {
  background-image: none;
  border-color: #e1e1e1;
}

.post-form .was-validated .post-form__search-box.form-control:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.post-form .post-form__search-btn {
  border-radius: 0.2rem;
  background: var(--color-main);
  border-color: var(--color-main);
}

.post-form .post-form__search-btn:hover,
.post-form .post-form__search-btn:focus {
  background: var(--color-sub);
  border-color: var(--color-sub);
  opacity: 1;
}

@media (min-width: 768px) {
  .post-form .post-form__search {
    width: 350px;
  }
}
.post-form .post-form__checkbox [type='checkbox'] {
  float: none;
  margin-right: 1rem;
}

.post-form .post-form__btn-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.post-form .post-form__btn {
  width: 300px;
}

.post-form .post-form__btn .btn {
  width: 100%;
  font-size: 1.5rem;
}

.post-form .post-form__btn:not(:first-child) .btn {
  margin-top: 1rem;
}

.post-form .post-form__btn-alert.post-form__btn-alert.post-form__btn-alert {
  background: var(--color-main);
}

.post-form
  .post-form__btn-alert.post-form__btn-alert.post-form__btn-alert:hover {
  background: var(--color-main);
  opacity: 0.5;
}

.post-form .post-form__error {
  padding-top: 0.5rem;
  color: var(--color-red);
  font-size: var(--font-size-s);
}

.post-form .post-form__error.post-form__error--center {
  text-align: center;
}
