.tag.tag--main {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0.3rem 0.5rem;
  font-size: 1rem;
  background: #81cac4;
  border-radius: 1rem;
}

.tag .tag__icon {
  color: #fff;
}

.tag .tag__icon-close {
  margin-left: 0.4rem;
  font-size: 1.4rem;
  color: #fff;
}

.tag .tag__label {
  color: #fff;
  padding-left: 0.25rem;
}
