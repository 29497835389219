@import '../../../properties.css';

.login-page__container {
  height: 100vh;
  background-color: #edf8fa;
}

.login-page__column {
  padding-top: 48px;
  padding-bottom: 48px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.login-page__column--right {
  background-color: #edf8fa;
}

.login-page__column--left {
  background-color: #fff;
}

.login-page__inner {
  max-width: 80%;
  width: 400px;
}

.login-page__title {
  order: 1;
  z-index: 2;
  max-width: 15rem;
  margin-bottom: 16px;
}

.login-page__title h1 {
  font-size: 32px;
  font-weight: 700;
}

@media (min-width: 576px) {
  .login-page__container {
    display: flex;
    align-items: stretch;
  }
  .login-page__column {
    height: 100vh;
  }
}
