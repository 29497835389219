@import '../../../properties.css';

.btn.btn {
  letter-spacing: 0.15rem;
  border-radius: 20px;
}

.btn.btn:hover {
  opacity: 0.5;
}

.btn.btn-main,
.btn.btn-main:hover {
  color: #fff;
  background: #5dc1cf;
  border-color: #5dc1cf;
}

.btn.btn-sub,
.btn.btn-sub:hover {
  color: #fff;
  background: #81cac4;
  border-color: #81cac4;
}

.btn.btn-gray,
.btn.btn-gray:hover {
  color: var(--color-black);
  background: var(--color-gray-lt);
  border-color: var(--color-gray-lt);
}

.btn.btn-main:hover,
.btn.btn-sub:hover {
  color: #fff;
}

.btn.btn-outline-main,
.btn.btn-outline-main:hover {
  color: #5dc1cf;
  border-color: #5dc1cf;
}

.btn.btn-outline-sub,
.btn.btn-outline-sub:hover {
  color: #81cac4;
  border-color: #81cac4;
}

.btn.btn-outline-gray,
.btn.btn-outline-gray:hover {
  border-color: var(--color-gray-lt);
  opacity: 1;
}

.btn.btn-outline-gray:hover {
  background: var(--color-gray-lt);
}

.btn.btn-map {
  color: #fff;
  background: var(--color-link);
  border-color: var(--color-link);
  font-size: 0.9rem;
  border-radius: 2rem;
  padding: 0.4rem 0.5rem;
}

.btn.btn-map-white {
  color: #000;
  background: var(--color-white);
  border-color: var(--color-white);
  font-size: 0.9rem;
  border-radius: 2rem;
  padding: 0.4rem 0.5rem;
}

.btn.btn-map:hover,
.btn-map-white:hover {
  text-decoration: none;
  opacity: 0.7;
}

.btn.btn-tab {
  background: var(--color-white);
  border-color: var(--color-white);
  font-size: 16px;
  border-radius: 2rem;
  padding: 8px 12px;
}

.btn.btn-tab-active {
  color: rgb(39, 103, 73);
  background: rgb(198, 246, 213);
  border-color: rgb(198, 246, 213);
  font-size: 16px;
  border-radius: 2rem;
  padding: 8px 12px;
}

.btn.btn-tab:hover,
.btn-tab-active:hover {
}
