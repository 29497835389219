@import '../../../properties.css';

.start-form {
  background-color: white;
  padding: 64px 32px;
}

@media (min-width: 576px) {
  .start-form {
    margin-top: 32px;
    background-color: white;
    padding: 64px 40px;
    border-radius: 4px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.29);
  }
}

.start-form__title {
  order: 1;
  margin: auto;
  z-index: 2;
  max-width: 100%;
  width: 240px;
  margin-bottom: 64px;
  padding-right: 24px;
}

.start-form__text-components {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 800;
  /* text-align:center; */
}

.start-form__file {
  display: none;
}

.start-form__form {
  margin-bottom: 24px;
}

.start-form__group {
  margin-bottom: 8px;
}

.start-form__btn-group {
  display: flex;
  justify-content: center;
}

.start-form__label--sub {
  margin-top: 4px;
  color: var(--color-gray);
  font-size: 14px;
}

.start-form__btn {
  width: 160px;
}

.start-form__btn .btn {
  width: 100%;
  font-size: 20px;
}

.start-form__cancel-btn {
  padding-top: 16px;
  font-size: 16px;
  text-align: center;
  color: var(--color-gray);
  cursor: pointer;
}
