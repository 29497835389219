@import '../../../properties.css';
.markdown-page {
  margin-top: 64px;
  padding: 24px;
}

.markdown-page__title {
  /* max-width: 450px; */
  margin: 3rem auto;
  padding: 0;
  border-bottom: 1px dotted var(--color-gray);
}
.markdown-page__title h1 {
  font-size: 1.5rem;
}

.markdown-page__contents {
  /* max-width: 450px; */
  margin: 3rem auto;
  padding: 0;
}

.markdown-page__contents h2 {
  margin-top: 3rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.markdown-page__contents ol > li {
  margin: 1rem 0;
}
