@import '../../../properties.css';

.posting-user {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.posting-user__body {
  flex-grow: 1;
  width: calc(100% - 3rem);
  padding-left: 1rem;
}

.posting-user__thumbnail {
  width: 3rem;
}

.posting-user__icons {
  margin-top: 0.6rem;
}
