@import '../../../properties.css';

.switching-router__tab.switching-router__tab {
  color: var(--color-main);
}

.switching-router__tab.switching-router__tab:hover {
  color: var(--color-main);
  opacity: 0.7;
}
