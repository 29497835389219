@import '../../../properties.css';

.counter-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 0.2rem;
  color: var(--color-white);
  border-radius: 0.8rem;
}

.counter-circle--main {
  background: var(--color-main);
}

.counter-circle--sub {
  background: var(--color-sub);
}

.counter-circle--black {
  background: var(--color-black);
}
