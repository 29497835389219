@import '../../../properties.css';

.image-carousel {
  height: auto;
  margin: 0 auto;
  --carousel-size-xl: 1100px;
  --carousel-size-lg: 900px;
  --carousel-size-sm: 100%;
}

@media (min-width: 400px) {
  .image-carousel {
    --carousel-size-sm: 280px;
  }
}

.image-carousel--xl {
  max-width: 90%;
  width: 100%;
}

.image-carousel--lg {
  width: 90%;
  max-width: 90%;
}

.image-carousel--sm {
  max-width: 100%;
  width: 100%;
}

.image-carousel__outer {
  position: relative;
}

.image-carousel__main {
  display: flex;
  align-items: stretch;
}

.image-carousel .carousel-inner,
.image-carousel__carousel,
.image-carousel__item {
  position: static;
  width: 100%;
}

.image-carousel__caption-elem {
  display: flex;
  align-items: stretch;
}

.image-carousel__indicators {
  width: 100%;
  padding: 0.5rem 0;
  background: var(--color-black);
}

.image-carousel__indicators--xl {
  width: var(--carousel-size-xl);
}

.image-carousel__indicators--lg {
  width: var(--carousel-size-lg);
}

.image-carousel__indicators--sm {
  display: none;
}

.image-carousel .carousel-control-prev {
  left: -10%;
}

.image-carousel--sm .carousel-control-prev {
  position: fixed;
  top: 30px;
  height: 30px;
  left: 35%;
}

.image-carousel .carousel-control-next {
  right: -10%;
}

.image-carousel--sm .carousel-control-next {
  position: fixed;
  top: 30px;
  height: 30px;
  right: 35%;
}

.image-carousel__icon {
  padding: 5px;
  fill: var(--color-gray-dk);
  background: var(--color-gray-lt);
  border-radius: 50%;
}

.image-carousel__icon--left {
  left: -5%;
}
