@import '../../../properties.css';

.user-thumbnail img {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.29);
  /* box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) */
}
.user-thumbnail img,
.user-thumbnail svg {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

.user-thumbnail__default {
  color: var(--color-gray-lt);
}
