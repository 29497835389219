@import '../../../properties.css';

.comment-img {
  /* 画像、コメントカードのサイズを管理 */
  --carousel-size-xl: 900px;
  --carousel-size-lg: 700px;
  --carousel-size-sm: 500px;
  --card-size-xl: 280px;
  --card-size-lg: 240px;
  --img-size-xl: calc(var(--carousel-size-xl) - var(--card-size-xl));
  --img-size-lg: calc(var(--carousel-size-lg) - var(--card-size-lg));
  display: flex;
  background: var(--color-gray-dk);
}

.comment-img--xl {
  max-width: 95%;
  margin: auto;
  width: var(--carousel-size-xl);
}

.comment-img--lg {
  max-width: 95%;
  margin: auto;
  width: var(--carousel-size-lg);
}

.comment-img--sm {
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  border: 1px solid var(--color-gray-lt);
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

/* 下部の画像一覧用、サムネイル画像のcssと競合したので、コメントアウト */
.comment-img img {
  width: 100%;
  height: auto;
  /* border-radius: .5rem 0 0 0; */
}

.comment-img--sm img {
  border-radius: 0.5rem 0.5rem 0 0;
}

.comment-img__img--xl {
  display: flex;
  align-items: center;
  width: var(--carousel-size-xl);
}

.comment-img__img--lg {
  display: flex;
  align-items: center;
  width: var(--img-size-lg);
}

.comment-img__img--sm {
  height: auto;
}

.comment-img__comment {
  flex-shrink: 0;
  align-self: stretch;
}

.comment-img__comment--xl {
  width: var(--card-size-xl);
  height: var(--img-size-xl);
}

.comment-img__comment--lg {
  width: var(--card-size-lg);
  height: 500px;
}

.comment-img__comment--xl .comment-card,
.comment-img__comment--lg .comment-card {
  display: flex;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.comment-img__comment--sm .comment-card {
  border: none;
}

.comment-img__comment .comment-card__header {
  flex-shrink: 0;
}

.comment-img__comment .comment-card__body {
  overflow-y: scroll;
}

.comment-img__comment--sm .comment-card__body {
  height: 250px;
}

.comment-img__comment .comment-card__footer {
  flex-shrink: 0;
}

.comment-img__menu-report,
.comment-img__menu-cancel {
  margin: 0;
  cursor: pointer;
}

.comment-img__menu-report {
  color: var(--color-red);
}

.comment-img__menu-cancel:hover {
  color: var(--color-link-hover);
}

.comment-img__menu {
  margin: 0;
  cursor: pointer;
}

.comment-img__menu-cancel:hover {
  color: var(--color-link-hover);
}
