.keyword-link {
  text-decoration: inherit;
}
.keyword-link--main {
  color: inherit;
}

.keyword-link:hover {
  text-decoration: none;
  cursor: pointer;
}

.keyword-link--black {
  color: var(--color-black);
}

.keyword-link--black:hover {
  color: var(--color-black);
  text-decoration: underline;
}
