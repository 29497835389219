.comment-menu-modal__item-danger,
.comment-menu-modal__item-cancel {
  margin: 0;
  cursor: pointer;
}

.comment-menu-modal__item-danger {
  color: var(--color-red);
}

.comment-menu-modal__item-cancel:hover {
  color: var(--color-link-hover);
}

.comment-menu-modal__item {
  margin: 0;
  cursor: pointer;
}

.comment-menu-modal__item:hover {
  color: var(--color-link-hover);
}
