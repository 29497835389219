@import '../../../properties.css';

.annotation {
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0.3rem;
  color: #fff;
  font-size: var(--font-size-xs);
  letter-spacing: 0.1rem;
  border-radius: 5px;
}

.annotation--danger {
  background: var(--color-red);
}

.annotation--main {
  background: var(--color-main);
}

.annotation--sub {
  background: var(--color-sub);
}

.annotation__text {
  display: inline;
  margin: 0;
  color: #fff;
  font-size: var(--font-size-xs);
  letter-spacing: 0.1rem;
}
