.social-item {
  display: flex;
}

.social-item__icon {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0.2rem 0.4rem;
  border: 1px solid var(--color-gray-lt);
  border-radius: 1rem;
  cursor: pointer;
}

.social-item__icon:not(:first-child) {
  margin-left: 0.5rem;
}

.social-item__icon--disabled {
  opacity: 0.4;
  cursor: default;
}

.social-item__icon-text {
  margin: 0;
  font-size: var(--font-size-xs);
  user-select: none;
}

.social-item__icon-text--like {
  padding-left: 0.3rem;
}

.social-item__icon-text--favorite {
  padding-left: 0.1rem;
}
