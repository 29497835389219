@import '../../../properties.css';

.loader {
  display: flex;
  justify-content: center;
  margin: 5em 0;
}

.loader .spinner-border {
  height: 3em;
  width: 3em;
}

/* .loader-app { */
/*   position: absolute; */
/*   top: 50%; */
/*   left: 50%; */
/*   transform: translate(-50%, -50%); */
/*   display: flex; */
/*   justify-content: center; */
/*   margin: 5em; */

/* } */
