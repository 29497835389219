.map-icon {
  display: flex;
  align-items: center;
  background: var(--color-map-icon);
  border-color: var(--color-map-icon);

  font-size: 1.6rem;
  padding: 0.7rem;
  border-radius: 0.5rem;

  cursor: pointer;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.29);
  /* border-radius: 2rem; */
}

.map-icon .icon__icon,
.map-with-pins__zoom-icon .icon__icon {
  padding: 0px;
}

/* マップ上のピンアイコンに斜線を引く */
.map-icon .icon__icon--hide {
  background: linear-gradient(
    45deg,
    var(--color-map-icon),
    var(--color-map-icon) 45%,
    white 45%,
    white 55%,
    var(--color-map-icon) 55%,
    var(--color-map-icon)
  );
}

.map-icon:hover,
.map-with-pins__zoom-icon:hover {
  text-decoration: none;
  opacity: 0.7;
}

@media (min-width: 576px) {
  .map-icon {
    font-size: 1.5rem;
    padding: 0.6rem;
    border-radius: 0.5rem;
  }
}
