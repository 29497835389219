@import '../../../properties.css';

.setting-form {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
}

.setting-form__file {
  display: none;
}

.setting-form__alert {
  padding: 0.6rem;
  margin: auto;
  width: 10rem;
}

.setting-form__thumbnail-group {
  margin-right: 64px;
  margin-bottom: 64px;
}

.setting-form__thumbnail {
  margin: auto;
  width: 6rem;
  margin-bottom: 16px;
}

.setting-form__thumbnail-group:hover {
  cursor: pointer;
}
.setting-form__thumbnail-btn:hover {
  color: var(--color-link-hover);
}
.setting-form__thumbnail-btn {
  font-size: 1rem;
  text-align: center;
  color: inherit;
  /* display: flex; */
  /* align-items: center; */
  /* flex-wrap: wrap; */
}
.setting-form__group {
  max-width: 320px;
  margin-bottom: 32px;
}

.setting-form__btn .btn {
  width: 128px;
  font-size: 16px;
}
