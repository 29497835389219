@import './properties.css';

html {
  background: var(--color-gray-p);
}

.app {
  background: var(--color-gray-p);
}

.app__body {
  min-height: 70vh;
}

.app__contents {
  margin: 0 auto;
}

.app__header-outer {
  width: 100%;
  background: #fff;
  /* border-bottom: 1px solid var(--color-gray-lt); */
  top: 0px;
  position: fixed;
  z-index: 1001;
}

.app__header-inner {
  margin: 0 auto;
  padding: 15px 20px 15px;
}

.app__footer footer {
  position: relative;
  z-index: 9;
}

.app__post {
  position: fixed;
  width: 80px;
  bottom: 16px;
  right: 16px;
  z-index: 400;
}

.app__post:hover {
  opacity: 0.7;
}

@media (min-width: 576px) {
  .app__post {
    width: 120px;
  }
}

@media (min-width: 1441px) {
  .app__post {
    right: calc((100% - 960px - 240px) / 2);
  }
}

/* for sweet alert */
.app-alert__radio.app-alert__radio.app-alert__radio {
  font-size: 1rem;
  flex-direction: column;
  align-items: start;
  font-size: 1rem;
}

.app-alert__radio.app-alert__radio.app-alert__radio label {
  margin: 0.3rem;
}

.app-alert__radio.app-alert__radio.app-alert__radio input {
  transform: scale(1.5);
}

.app-alert__confirm-btn.app-alert__confirm-btn.app-alert__confirm-btn {
  background: var(--color-main);
}

.app-alert__confirm-btn.app-alert__confirm-btn.app-alert__confirm-btn:hover {
  background: var(--color-main);
  opacity: 0.6;
}

.app__title {
  /* max-width: 450px; */
  /* margin-top: 2rem; */
  margin: 2rem 0;

  /* text-align:center; */
  /* padding: 0; */
}

.app__title h1 {
  font-size: 24px;
  font-weight: 700;
  /* text-align: center; */
}

.app__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* page読み込み中のときのLoader用。 */
.page__loading .loader {
  margin: 10rem 0;
}

.page__map {
  margin-top: var(--header-height);
}

.page__contents {
  padding: 24px;
}

@media (min-width: 1068px) {
  .page__map {
    margin-top: var(--header-height);
    width: 100%;
    height: 100%;
    width: calc(100% - 720px);
    position: fixed;
    overflow: hidden;
  }

  .page__contents {
    padding: 16px 24px;
    margin-top: 90px;
    float: right;
    right: 0;
    width: 720px;
    min-height: calc(100vh - var(--header-height));
  }
}

.page__filter {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-start;
}

.page__filter > div {
  margin-right: 1rem;
  margin-top: auto;
  margin-bottom: auto;
}

.footer-share-buttons {
  display: flex;
  justify-content: right;
  width: 100%;
  padding: 8px;
}

.footer-share-buttons > button {
  margin-left: 4px;
}
