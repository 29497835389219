@import '../../../properties.css';

.posting-comment {
  display: flex;
  justify-content: space-between;
}

.posting-comment__thumbnail {
  width: 3rem;
}

.posting-comment__body {
  flex-grow: 1;
  width: calc(100% - 3rem);
  padding-left: 1rem;
  word-wrap: break-word;
  word-break: break-all;
}

.posting-comment__name {
  margin: 0;
}

.posting-comment__comment {
  margin: 0.2rem 0 0;
}

.posting-comment__title {
  margin: 0.2rem 0 0;
}

.posting-comment__posted-diff-day {
  margin: 0.4rem 0 0;
  color: var(--color-gray-lt);
  font-size: var(--font-size-s);
}

.posting-comment__menu {
  font-size: 1.1rem;
}
.posting-comment__menu:hover {
  color: var(--color-link);
  cursor: pointer;
}

.posting-comment__delete {
  min-width: 4rem;
  margin: 0;
  color: var(--color-main);
  text-align: right;
  background: none;
  border: none;
  cursor: pointer;
}
