@import '../../../properties.css';

.search-form__btn-group {
  margin: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /* border-bottom-right-radius: calc(.3rem - 1px); */
  /* border-bottom-left-radius: calc(.3rem - 1px); */
}

.search-form__controls {
  margin: 16px 8px 32px;
}

.search-modal.search-modal--s {
  max-width: 480px;
}
