.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-button__icon {
  padding-right: 7.5px;
}

.icon-button__icon svg {
  float: left;
  width: 1.2rem;
  height: auto;
}
