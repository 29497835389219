@import '../../../properties.css';

.posting-content {
  display: flex;
  justify-content: space-between;
}

.posting-content__thumbnail {
  width: 3rem;
}

.posting-content__body {
  flex-grow: 1;
  width: calc(100% - 3rem);
  padding-left: 1rem;
  word-wrap: break-word;
  word-break: break-all;
}

.posting-content__name {
  margin: 0;
}

.posting-content__title {
  margin: 0.4rem 0 0;
}

.posting-content__keyword-list {
  margin-top: 0.25rem;
  color: var(--color-link);
}

.posting-content__posted-diff-day {
  margin: 0.4rem 0 0;
  color: var(--color-gray-lt);
  font-size: var(--font-size-s);
}
