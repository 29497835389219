@import '../../../properties.css';

.icon-counter {
  display: flex;
  align-items: center;
}

.icon-counter__icon {
  display: flex;
  align-items: center;
  padding-right: 0.25rem;
}
