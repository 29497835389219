@import '../../../properties.css';

.tags {
  display: flex;
}

.tags__title {
  min-width: 55px;
  margin: 0;
  font-size: var(--font-size-m);
}

.tags__list {
  flex-shrink: 1;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding-left: 0.5rem;
  font-size: var(--font-size-s);
  word-break: break-all;
  list-style: none;
}

.tags__item {
  margin: 0 0.5rem;
}

.tags__item a {
  color: var(--color-link);
}

.tags__item a:hover {
  color: var(--color-link-hover);
}

@media (max-width: 575px) {
  .tags {
    flex-direction: column;
    align-items: center;
  }

  .tags__title {
    min-width: auto;
    margin-bottom: 0.3rem;
  }

  .tags__list {
    padding-left: 0;
  }

  /* .tags__item:first-child {
    margin-left: 0;
  } */
}
