@import '../../../properties.css';

.my-page__display-switching {
  margin-top: 2.5rem;
  font-size: var(--font-size-s);
}

/* タブを背景色と同じグレーにする */
.my-page .my-page__display-switching .nav-link.active {
  background: #f6f6f6;
  border-color: #dee2e6;
}

.my-page__display-items {
  margin-top: 1rem;
}

.my-page__user {
  display: flex;
  justify-content: flex-start;
}

.my-page__setting-btn .btn {
  width: 10rem;
  margin-left: 2rem;
}

.my-page__filter {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-start;
}

.my-page__filter > div {
  margin-right: 1rem;
  margin-top: auto;
  margin-bottom: auto;
}
