.post-page {
  max-width: 960px;
  margin: calc(var(--header-height) + 32px) auto 64px;
  padding: 0px 16px;
}

.post-page__title {
  margin-top: 32px;
}

.post-page__title h1 {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
}
