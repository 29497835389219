@import '../../../properties.css';

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--color-white);
  text-align: center;
  background: #fff;
  border-top: 1px solid var(--color-gray-lt);
}

.footer .footer__main {
  display: flex;
  width: 100%;
  margin: 0 auto;
}

.footer .footer__logo {
  margin-right: 1rem;
  color: var(--color-main);
  max-width: 12rem;
}

.footer .footer__logo h1 {
  font-size: 3rem;
}

.footer .footer__logo h2 {
  margin-top: 0.75rem;
}

.footer .footer__body {
  display: flex;
  color: var(--color-black);
  letter-spacing: 0.2rem;
}

.footer .footer__body a {
  font-size: 0.7rem;
}

.footer .footer__category {
  font-size: var(--font-size-xs);
  text-align: left;
}

.footer .footer__sub-category:not(:last-child) {
  margin-bottom: 1.5rem;
}

.footer .footer__sub-category p {
  margin: 0;
}

.footer .footer__title {
  font-size: var(--font-size-s);
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.footer .footer__copyright {
  margin: 2.5rem 0 0;
  color: var(--color-black);
  font-size: var(--font-size-xs);
  letter-spacing: 0.2rem;
}

@media (max-width: 575px) {
  .footer {
    padding: 0.75rem 0 1rem;
  }

  .footer .footer__main {
    flex-direction: column;
    align-items: center;
  }

  .footer .footer__body {
    flex-direction: column;
  }

  .footer .footer__body .keyword-list__item {
    margin-top: 0.4rem;
  }

  .footer .footer__category {
    margin-top: 2rem;
  }
}

@media (min-width: 576px) {
  .footer {
    padding: 16px 20px 16px;
  }

  .footer .footer__main {
    flex-direction: column;
    justify-content: space-between;
  }

  .footer .footer__body {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1.5rem;
  }

  .footer .footer__body .keyword-list__item {
    margin-top: 0.2rem;
  }

  .footer .footer__category:not(:last-child) {
    padding-right: 1rem;
  }
}

@media (min-width: 786px) {
  .footer {
    padding: 16px 20px 16px;
  }

  .footer .footer__main {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .footer .footer__body {
    margin-top: 0;
  }

  .footer .footer__copyright {
    width: 100%;
    text-align: center;
  }
}

@media (min-width: 960px) {
  .footer .footer__category:not(:last-child) {
    padding-right: 3rem;
  }
}
