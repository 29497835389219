@import '../../../properties.css';

.user-page__post-list {
  margin-top: 1rem;
}

@media (max-width: 576px) {
  .user-page__user-profile .user-profile {
    justify-content: center;
  }
}
