@import '../../../properties.css';

.file-upload {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 1rem;
  text-align: center;
  background: #fff;
  border: 2px dashed var(--color-gray-lt);
  cursor: pointer;
  user-select: none;
}

.file-upload:hover {
  opacity: 0.6;
}

.file-upload__icon {
  font-size: 2rem;
}

.file-upload__text {
  margin-top: 0.7rem;
  line-height: 1.7rem;
}

.file-upload .file-upload__file {
  display: none;
}

.file-upload__img {
  height: 275px;
}
