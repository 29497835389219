@import '../../../properties.css';

.button {
  border-radius: 20px !important;
}
.oauth-buttons__buttons {
  align-items: stretch;
}

.oauth-buttons__buttons > button {
  position: relative;
  width: 100%;
  /* padding: 20%; */
  display: flex;
  justify-content: center;
  align-items: center;
}
