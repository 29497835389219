@import '../../../properties.css';

.post-profile {
  display: flex;
  justify-content: space-between;
}

.post-profile__body {
  flex-grow: 1;
  width: calc(100% - 3rem);
  padding-left: 1rem;
}

.post-profile__area,
.post-profile__post-num {
  margin: 0;
  font-weight: bold;
}

.post-profile__post-num,
.post-profile__tag-filed {
  font-size: var(--font-size-s);
}

.post-profile__tag-filed {
  display: flex;
  margin-top: 0.5rem;
}

.post-profile__tag-title {
  margin: 0 1.5rem 0 0;
  color: var(--color-gray);
  word-break: keep-all;
}

.post-profile__tag-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.post-profile__tag-item a {
  color: var(--color-link);
}

.post-profile__tag-item a:hover {
  color: var(--color-link-hover);
}

.post-profile__tag-item:not(:last-child) {
  margin-right: 0.75rem;
}

@media (max-width: 575px) {
  .post-profile {
    width: 300px;
  }

  .post-profile__tag-filed {
    flex-direction: column;
  }
}
