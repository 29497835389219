@import '../../../properties.css';

.header__main {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.header__title {
  color: var(--color-main);
  z-index: 2;
  max-width: 144px;
  max-height: 48px;
  margin-bottom: 0rem;
  margin-right: 32px;
}

.header__sub {
  order: 3;
  width: 100%;
  /* margin-top: 0.5rem; */
  text-align: center;
}

.header__sub-title {
  margin: 0;
  color: var(--color-main);
  font-size: var(--font-size-s);
}

.header__left-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__left-items .header__btns {
  margin-right: 4px;
}

.header__menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.header__menu > div {
  margin: 0px 4px;
}

.header__menu .header__post-btn .btn {
  font-size: var(--font-size-m);
  width: 9rem;
}
.header__menu .header__post-btn .icon-button__icon svg {
  width: 24px;
}

@media (max-width: 575px) {
  .header__menu .header__post-btn {
    display: none;
  }
}
@media (max-width: 767px) {
  .header__btn-group {
    display: none;
  }
}

.header__menu .header__search {
  font-size: 24px;
  margin-right: 8px;
}

.header__menu .btn {
  width: 6rem;
  padding: 0.2rem 0.2rem;
  font-size: var(--font-size-x);
}

.header__sign-in {
}

.header .header__thumbnail {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  width: 64px;
}

.header__dropdown {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.header__dropdown:after {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
  content: '';
  margin-left: 4px;
}

@media (min-width: 576px) {
  /* .header__btns {
    order: 3;
    margin-left: 1rem;
  } */
  .header__menu .header__search {
    margin-right: 16px;
  }

  .header__menu .btn {
    width: 8rem;
    padding: 0.375rem 0.75rem;
    font-size: var(--font-size-m);
  }
}
