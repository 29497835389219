@import '../../../properties.css';

.main-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-title__main {
  margin: 0;
  font-size: var(--font-size-xxxxl);
}

.main-title__img {
  width: 100%;
  height: auto;
  font-size: inherit;
}

.main-title__link {
  color: inherit;
  text-decoration: none;
}

.main-title__link:hover {
  color: inherit;
  text-decoration: none;
  opacity: 0.5;
}

.main-title__sub {
  margin: 0.25rem 0 0;
  font-size: var(--font-size-s);
  text-align: center;
}
