@import '../../../properties.css';

.user-profile {
  display: flex;
}

.user-profile__thumbnail {
  width: 4rem;
  margin-right: 0.25rem;
}

.user-profile__body {
  padding-left: 1rem;
}

.user-profile__name,
.user-profile__post-num {
  margin: 0;
  font-weight: bold;
}

.user-profile__post-num,
.user-profile__tag-filed {
  font-size: var(--font-size-s);
}
