.map-with-pins {
  position: relative;
}

/* スナップページもこのクラスに依存している */
.leaflet-container {
  width: 100%;
  height: calc(100vh - var(--header-height));
}
@media (max-width: 575px) {
}

@media (max-width: 768px) {
}

.map-with-pins__tooltip {
  width: 100px;
  background-color: transparent;
  border-top-color: transparent;
  border: none;
  box-shadow: none;
}

.map-with-pins__tooltip:before {
  border-top-color: transparent;
}

.map-with-pins__label-icon {
  width: max-content !important;
}

.map-with-pins__label-icon a {
  color: var(--color-white);
  font-size: var(--font-size-xl);
  font-weight: bold;
  text-decoration: none;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.29);
}

.map-with-pins__label-icon a:hover {
  opacity: 0.7;
}

.map-with-pins__post {
  position: fixed;
  width: 80px;
  bottom: 20px;
  right: 4px;
  z-index: 1001;
}

@media (min-width: 1068px) {
  .map-with-pins__post {
    position: absolute;
  }
}

/* .map-control .leaflet-control { */
/*   border: none; */
/* } */

.map-with-pins__control-btn > div {
  margin: 0.5rem;
}

.map-with-pins__control-btn--topleft {
  position: absolute;
  top: 128px;
  left: 4px;
  z-index: 400;
}

.map-with-pins__control-btn--top {
  position: absolute;
  top: 16px;
  left: 4px;
  z-index: 400;
}

.map-with-pins__control-btn--top > button {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.29);
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  margin-left: 8px;
}
.map-with-pins__control-btn--top .icon-button__icon svg {
  width: 0.8rem;
}

.map-with-pins__control-btn--topright {
  position: absolute;
  top: 128px;
  right: 4px;
  z-index: 400;
}

@media (min-width: 576px) {
}
.map-with-pins__zoom-icon {
  display: flex;
  align-items: center;
  background: var(--color-white);
  border-color: var(--color-white);
  padding: 0.4rem;
  font-size: 1.9rem;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.29);
  cursor: pointer;
}

.map-with-pins__zoom-icons > div:first-child {
  border-bottom: 1px solid var(--color-gray-lt);
  border-radius: 0.5rem 0.5rem 0 0;
}

.map-with-pins__zoom-icons > div:nth-child(2) {
  border-bottom: 1px solid var(--color-gray-lt);
  border-radius: 0 0 0.5rem 0.5rem;
}

.map-with-pins__scroll-top {
  position: fixed;
  top: 90px;
  left: 20px;
  z-index: 400;
  width: 8rem;
  animation: fadein 1s cubic-bezier(0.22, 1, 0.36, 1) 1 forwards;
}

.map-with-pins__scroll-bottom {
  position: fixed;
  bottom: 12px;
  left: 12px;
  z-index: 400;
  width: 10rem;
  animation: fadein 1s cubic-bezier(0.22, 1, 0.36, 1) 1 forwards;
}

.map-with-pins__scroll-top.map-with-pins__scroll-top .btn,
.map-with-pins__scroll-bottom.map-with-pins__scroll-bottom .btn {
  font-size: 12px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.29);
}

/* .map-with-pins__icon { */
/*   padding: 5px; */
/*   fill: var(--color-gray-dk); */
/*   background: var(--color-gray-lt); */
/*   border-radius: 50%; */
/*   box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.29); */
/*   opacity: 0.6; */
/* } */

/* .map-with-pins__icon:hover { */
/*   opacity: 0.3; */
/* } */

/* .map-with-pins__btn .btn { */
/*   padding: .5rem; */
/*   border-radius:30px; */
/*   font-size: var(--font-size-m); */
/* } */
/* .map-with-pins__btn .icon-button__icon svg{ */
/*   width: 2rem; */
/* } */
/* .map-with-pins__btn .icon-button__icon{ */
/*   padding-right: 0px; */
/* } */

.leaflet-container .map-pin {
  animation: fadein 2s cubic-bezier(0.22, 1, 0.36, 1) 1 forwards;
  /* animation: fadein 2s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;; */
}

/* @keyframes popup { */
/*   0% { */
/*     transform: translateY(10px) scale(0.8); */
/*     opacity: 0; */
/*   } */
/*   100% { */
/*     transform: translateY(0) scale(1.0); */
/*   } */
/*   80%, 100% { */
/*     opacity: 1; */
/*   } */
/* } */

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
