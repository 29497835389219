@import '../../../properties.css';

.map-pin {
  position: relative;
  background: var(--color-white);
  border-radius: 0.5rem;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
}

.map-pin:hover {
  opacity: 0.5;
  cursor: pointer;
}

.map-pin--l {
  width: 140px;
  height: 140px;
}

.map-pin--m {
  width: 80px;
  height: 80px;
}

.map-pin--s {
  width: 40px;
  height: 40px;
}

.map-pin::after {
  content: '';
  display: block;
  position: absolute;
  background: #fff;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.3);
  transform: rotate(45deg);
}

.map-pin--s::after {
  bottom: calc(0px - var(--mapicon-size-s) / 2);
  left: calc(50% - var(--mapicon-size-s) / 2);
  width: var(--mapicon-size-s);
  height: var(--mapicon-size-s);
}

.map-pin--m::after {
  bottom: calc(0px - var(--mapicon-size-m) / 2);
  left: calc(50% - var(--mapicon-size-m) / 2);
  width: var(--mapicon-size-m);
  height: var(--mapicon-size-m);
}

.map-pin--l::after {
  bottom: calc(0px - var(--mapicon-size-l) / 2);
  left: calc(50% - var(--mapicon-size-l) / 2);
  width: var(--mapicon-size-l);
  height: var(--mapicon-size-l);
}

.map-pin img {
  position: relative;
  width: inherit;
  height: inherit;
  border: 3px solid var(--color-white);
  border-radius: 0.5rem;
  background: #fff;
  z-index: 2;
  object-fit: cover;
}

.map-pin__counter {
  position: absolute;
  height: auto;
  padding: 0.2rem;
  z-index: 3;
}

.map-pin__counter--l {
  width: 50px;
  top: -20px;
  right: -20px;
  font-size: var(--font-size-l);
}

.map-pin__counter--m {
  width: 35px;
  top: -14px;
  right: -14px;
  font-size: var(--font-size-s);
}

.map-pin__counter--s {
  width: 35px;
  top: -10px;
  right: -18px;
  font-size: 0.7rem;
}

.map-pin__counter--s .counter-circle {
  height: 13.5px;
}
