@import '../../../properties.css';

.crop {
  width: 100%;
}

.crop__container {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.crop__container--m {
  max-height: 500px;
}

.crop__container--s {
  max-height: 300px;
}

.crop__controls {
  width: 100%;
  margin: 0 1rem;
}
.crop__slider-label {
  width: 5rem;
  text-align: center;
  margin: auto;
}

.crop__slider {
  width: 90%;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.crop__slider .crop__slider-slider {
  margin: 0 1rem;
  width: 100%;
}

.crop__footer {
  padding: 1rem;
  background-color: var(--color-white);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #dee2e6;

  background: var(--color-gray-p);
  /* border-bottom-right-radius: calc(.3rem - 1px); */
  /* border-bottom-left-radius: calc(.3rem - 1px); */
}

.crop-modal .crop__footer button {
  margin: 0.3rem;
  width: 10rem;
}

.reactEasyCrop_CropArea {
  color: rgba(0, 0, 0, 0.5) !important;
}

.crop-modal .modal-dialog {
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.5);
}

.crop-modal--s .modal-dialog {
  max-width: 300px;
}

.crop-modal .crop-modal__body {
  padding: 0;
}

.crop-modal .modal-content {
  border: none;
}
